.wp-block-button .wp-block-button__link {
  border-radius: 0 !important;
}

.wp-block-button.is-style-default {
  padding: 0 !important;
  color: $color-black;
  background-color: transparent !important;

  &:enter,
  &:active {
    background-color: transparent !important;
  }
}

.wp-block-button.is-style-default .wp-block-button__link {
  padding: 14px 24px !important;
  color: $color-black !important;
  background-color: $color-primary !important;

  &:enter,
  &:active {
    background-color: $color-primary-state !important;
  }
}

.wp-block-button.is-style-light {
  border: none !important;
  background-color: transparent !important;

  &:enter {
    background-color: transparent !important;
  }
}

.wp-block-button.is-style-light .wp-block-button__link {
  border: 1px solid $color-black !important;
  background-color: $color-white !important;
  color: $color-black !important;

  &:enter {
    background-color: $color-grey-brand !important;
  }
}

.wp-block-button.is-style-dark {
  background-color: transparent !important;
  border: none !important;

  &:enter {
    background-color: transparent !important;
  }
}

.wp-block-button.is-style-dark .wp-block-button__link {
  background: $color-black !important;
  color: $color-white !important;
  border: 1px solid $color-black !important;

  &:enter {
    background-color: $color-grey-mid-dark !important;
  }
}

[data-type="core/button"][data-align="center"] .wp-block-button-wrapper {
  margin-right: auto;
  margin-left: auto;
}

.wp-block-button.is-style-link {
  background-color: transparent !important;
  border: none !important;

  &:enter {
    background-color: transparent !important;
  }
}

.wp-block-button.is-style-link .wp-block-button__link {
  color: inherit;
  border: none;
  background-color: transparent;
  font-family: "Amnesty Trade Gothic", sans-serif;
  font-weight: normal;
  text-transform: capitalize;
  text-decoration: underline;
}

.wp-block-button.is-style-link .wp-block-button__link::before {
  @include icon(290px, 200px, 17px, 11px);
  content: " ";
  display: inline-block;
  vertical-align: middle;
}

.rtl .wp-block-button.is-style-link .wp-block-button__link::before {
  content: none;
}

.rtl .wp-block-button.is-style-link .wp-block-button__link::after {
  @include icon(290px, 200px, 17px, 11px);
  content: " ";
  display: inline-block;
  vertical-align: middle;
  transform: scaleX(-1);
}
