.clockdiv {
  justify-content: center;
}

.countdownClock {
  font-size: 5.2em;
  color: $color-black;
  font-family: var(--font-family-secondary);
  text-transform: uppercase;
}

.countdownContainer {
  border: 1px solid $color-black;
  padding: 20px;
}

.expiredTextBox {
  font-weight: bold;
}

.section--textWhite .countdownClock {
  color: $color-white;
}
