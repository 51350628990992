[data-type="amnesty-core/block-list"] {
  max-width: 100% !important;
  width: 100% !important;
}

.linklist-container {
  max-width: 600px;
  margin: 0 auto;
}

.edit-post-visual-editor .linkList:not(.wp-block-gallery) {
  list-style: none;
}

.linkList-item,
.grid-item {
  position: relative;

  .editor-url-input__button {
    position: relative;
  }

  .editor-url-input__button-modal {
    position: absolute;
    left: 0;
    width: 340px;
    z-index: 999;
  }
}

.grid-itemTitle a,
.grid-itemMeta,
.linkList-itemTitle,
.linkList-itemMeta {
  display: inline-flex;
  align-items: center;
  font-family: var(--font-family-secondary);
}

.grid-itemContent {
  font-family: var(--font-family-primary);
}

.linkList-options {
  position: absolute;
  z-index: 10;
  right: 0;
  top: 0;
  background-color: $color-white;

  .rtl & {
    right: unset;
    left: 0;
  }
}

.wp-block-bigbite-postlist {
  border: 1px solid $color-grey-xx-light;

  .filter select {
    margin-right: 10px;
  }

  .title-wrapper {
    padding: 10px;
  }

  .post-selectorHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    background-color: $color-grey-xx-light;
  }

  .post-selectorHeader .searchbox label,
  .post-selectorHeader .searchbox {
    display: flex;
    flex-grow: 1;
    align-items: center;
  }

  .post-selectorHeader .searchbox svg {
    width: 25px;
    height: 25px;
    fill: $color-grey-base;
  }

  .post-selectorHeader input {
    width: 100%;
    margin-left: 8px;
  }

  .post-selectorHeader input,
  .post-selectorHeader select {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
    border-radius: 0;
    padding: 8px;
    font-size: 18px;
    border: none;
    box-shadow: none;
    max-height: none;
    height: auto;
    background-color: transparent;
    transition: all .25s ease-in-out;
    border-bottom: 2px solid transparent;
  }

  .post-selectorHeader select {
    margin-left: 14px;
    background-color: $color-white;
  }

  .post-selectorHeader input:focus {
    box-shadow: none;
    border-bottom: 2px solid $color-blue-xx-light;
  }

  .post {
    position: relative;
    display: flex;
    align-items: flex-start;
    margin-bottom: 8px;
  }

  .post .editor-block-icon .dashicons-plus {
    margin-top: 4px;
  }

  .post-figure {
    width: 50px;
    height: 50px;
    flex-basis: 50px;
    flex-shrink: 0;
    background-color: $color-grey-x-light;
    background-repeat: no-repeat;
    background-size: cover;
    margin: 0;
    margin-right: 16px;
  }

  .post-title {
    margin: 0;
    line-height: 1;
    margin-bottom: 4px;
    font-size: 18px;
  }

  .post-meta {
    font-size: 14px;
    font-style: oblique;
    color: rgb(85, 93, 102);
  }

  .post-selectorContainer {
    display: flex;
    justify-content: space-between;
    margin: 5px 0;
    padding: 0 10px;
  }

  .post-list {
    flex-basis: 50%;
    padding: 0 1%;
    max-height: 200px;
    height: 200px;
    overflow-y: auto;

    &:first-child {
      border-right: 1px solid $color-grey-x-light;
    }
  }

  .post button {
    position: absolute;
    top: 50%;
    right: 5px;
    opacity: 0;
    z-index: -999;
    width: 35px;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $color-grey-x-light;
    border: none;
    border-radius: 50%;
    box-shadow: 1px 1px 5px 1px rgba($color-black-mid-dark, .23);
    transform: translate(0, -50%);
  }

  .post:hover button {
    opacity: 1;
    z-index: 100;
  }

  .post-list > button {
    background-color: $color-blue-xx-light;
    width: 100%;
    padding: 12px 18px;
    font-size: 14px;
    font-weight: bold;
    border: none;
    color: $color-white;
    margin-bottom: 8px;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
    transition: all .25s ease-in-out;
  }

  .post-list > button:not([disabled]):hover,
  .post-list > button:not([disabled]):focus,
  .post-list > button:not([disabled]):active {
    background-color: $color-blue-sm-light;
  }

  .post-list > button[disabled] {
    opacity: .7;
  }

  .blocks-plain-text {
    line-height: 1.8;
    font-size: 1.6em;
    font-weight: bold;
  }
}

.grid-itemTitle {
  margin-top: 0;
}

.grid-itemMeta {
  overflow: visible;
}
