.blockquote {
  width: 100%;
}

.blockquote cite.editor-rich-text__tinymce.mce-content-body[data-is-placeholder-visible="true"] {
  border-bottom: none;
}

.wp-block-freeform.block-library-rich-text__tinymce blockquote {
  border: none !important;
  padding-left: 0;
}

.blockquote div p {
  font-family: var(--font-family-secondary);
  line-height: 2 !important;
}

.blockquote div cite {
  line-height: 40px !important;
  padding-bottom: 24px;
}
