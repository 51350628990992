.factBlock {
  margin-right: auto;
  margin-left: auto;
}

.edit-post-visual-editor .editor-block-list__block[data-type="amnesty-core/key-fact"]:not(:last-of-type) {
  position: relative;
  margin-bottom: 50px;
}

.edit-post-visual-editor .editor-block-list__block[data-type="amnesty-core/key-fact"]:not(:last-of-type)::before,
.edit-post-visual-editor .editor-block-list__block[data-type="amnesty-core/key-fact"]:not(:last-of-type)::after {
  content: "";
  position: absolute;
  display: block;
  background-color: $color-grey-dark;
}

.edit-post-visual-editor .editor-block-list__block[data-type="amnesty-core/key-fact"]:not(:last-of-type)::before {
  @include icon(10px, 162px, 40px, 40px);
  left: calc(50% - 20px);
  top: calc(100% + 5px);
  z-index: 1;
  width: 40px;
  height: 40px;
}

.edit-post-visual-editor .editor-block-list__block[data-type="amnesty-core/key-fact"]:not(:last-of-type)::after {
  bottom: -26px; // margin/2 + height/2
  left: 5%;
  height: 2px;
  width: 90%;
}
