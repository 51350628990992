.edit-post-visual-editor .editor-block-list__block[data-type="amnesty-core/block-call-to-action"] {
  max-width: 100%;
}

.callToAction * {
  font-family: var(--font-family-secondary);
}

.callToAction .editor-rich-text__tinymce.mce-content-body {
  line-height: 1.3;
}

.callToAction-heading {
  margin-bottom: 24px;
}

.callToAction-content {
  margin-bottom: 24px;
}

.callToAction-buttonContainer {
  position: relative;

  [data-type="amnesty-core/block-button"] {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .block-editor-url-input__button {
    display: inline-flex;
    margin-left: 10px;
  }

  .block-editor-url-input__button-modal {
    position: absolute;
    top: 100%;
    margin-top: 5px;
  }
}

.callToAction .editor-rich-text__tinymce.mce-content-body:not(:last-child) {
  // Targets the input when the placeholder is visible.
  left: 50%;
  transform: translateX(-50%);
}

.callToAction-buttonContainer .block-editor-block-list__layout {
  display: inline-flex;
  align-items: center;
}

.callToAction-buttonContainer .block-editor-block-list__layout .wp-block-button {
  margin: 0;
}
