.block-editor .wp-block-latest-posts,
.block-editor .wp-block-latest-posts.is-grid {
  padding: 24px;
  margin: 0;
}

.block-editor .section--tinted .wp-block-latest-posts,
.block-editor .section--tinted .wp-block-latest-posts.is-grid {
  padding-left: 0;
  padding-right: 0;
}
