.block-editor-page.post-type-pop-in .edit-post-visual-editor {
  background-color: $color-grey-light;
  text-align: center;
}

.block-editor-page.post-type-pop-in .editor-post-title__input {
  text-align: center;
}

.block-editor-page.post-type-pop-in .wp-block-buttons .block-list-appender {
  display: block;
  margin: -16px auto 0;
  max-width: 50px;
}
