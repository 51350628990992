.wp-block[data-type="amnesty-core/iframe-button"] {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

.wp-block[data-type="amnesty-core/iframe-button"] .iframeButton.is-style-default [data-rich-text-placeholder]::after {
  color: $color-black !important;
}

.wp-block[data-type="amnesty-core/iframe-button"] .components-placeholder__input {
  margin-right: 12px;
  margin-left: 12px;
}

.wp-block[data-type="amnesty-core/iframe-button"] .iframeButton-inputWrapper {
  border: 1px dotted;
  display: flex;
  flex-grow: 1;
  padding: 4px;
}

.wp-block[data-type="amnesty-core/iframe-button"] .iframeButton ~ .iframeButton-iframeWrapper {
  margin-top: 12px;
  width: 100%;
}
