.element-select {
  box-sizing: border-box;
  position: relative;
  display: inline-block;
  margin-right: 10px;
  width: 300px;
  height: 56px;
  background-color: $color-white;

  select {
    padding: 10px;
    width: 100% !important;
    height: 100% !important;
    border-radius: 0 !important;
    border: 2px solid;
  }
}

.section--textWhite .element-select::after {
  background-position: -271px -131px;
}

.section--textWhite .element-select select {
  color: $color-white;
  border-color: $color-white;
}

.download-block {
  padding: 2px;
  border: 1px dashed;
}

.download-block > div:first-of-type {
  max-height: none !important;
}

.wp-core-ui .download-block select {
  background: none;
}

.wp-block[data-type="amnesty-core/block-download"] .block-editor-media-placeholder.is-appender {
  outline: none;
}

.download-block.center {
  display: flex;
  justify-content: center;
}

.download-block.left {
  display: flex;
  justify-content: flex-start;
}

.download-block.right {
  display: flex;
  justify-content: flex-end;
}
