.wp-block[data-type="amnesty-core/background-media"] {
  max-width: 1024px;
}

.wp-block[data-type="amnesty-core/background-media-column"] {
  max-width: 1024px;
  border: 1px dotted;
}

.wp-block-amnesty-core-background-media {
  border: 1px solid $color-grey-x-light;
}

.wp-block-amnesty-core-background-media > .block-editor-inner-blocks {
  width: 100%;
}

.wp-block-amnesty-core-background-media > .block-editor-inner-blocks > .block-editor-block-list__layout {
  display: flex;
  width: 100%;

  .block-editor-block-list__block {
    flex-grow: 0;
    flex-basis: 45%;
  }
}

.wp-block-amnesty-core-background-media .text-media--backgroundImage {
  opacity: .5;
}

.wp-block-amnesty-core-background-media .text-media--itemContainer {
  position: relative;
  flex-direction: column;
  padding-top: 48px;
}

.wp-block-amnesty-core-background-media .text-media--itemContainer > div:first-of-type {
  position: absolute;
  top: 12px;
  left: 12px;
}

.wp-block-amnesty-core-background-media .components-button {
  align-self: flex-start;

  .rtl & {
    align-self: flex-end;
  }
}
