.tweetAction .editor-plain-text {
  border-radius: 0 !important;
}

.tweetAction-title {
  display: inline-block !important;
  padding: 10px !important;
  max-width: calc(100% - 54px);
  background: transparent;
  font-family: var(--font-family-secondary) !important;
  font-size: 28px !important;
  font-weight: bold;
  line-height: 1.4 !important;
  text-transform: uppercase;
}

.tweetAction-content {
  margin-top: 16px !important;
  margin-bottom: 16px !important;
  padding: 10px !important;
  font-family: var(--font-family-primary) !important;
  font-size: 21px !important;
}

.tweetAction-button {
  padding-right: 10px;
  padding-left: 10px;
}

.block-editor-block-list__layout .tweetBlock {
  display: flex;
  min-width: 400px;
}

.block-editor-block-list__layout .tweetBlock .align-left {
  justify-content: flex-start;
}

.block-editor-block-list__layout .tweetBlock .align-center {
  justify-content: center;
}

.block-editor-block-list__layout .tweetBlock .align-right {
  justify-content: flex-end;
}
